import { AppProps } from "next/app";
import { Provider } from 'react-redux';
import { useStore } from '../store';
import "../styles/style.css";
import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || "https://examplePublicKey@o0.ingest.sentry.io/0",
  integrations: [
    new CaptureConsole({
      levels: ['error']
    })
  ],
  autoSessionTracking: false,
  environment: process.env.NODE_ENV,
  maxBreadcrumbs: 50
})

function MyApp({ Component, pageProps }: AppProps) {

    const store = useStore(pageProps.initialReduxState);

    return (
        <Provider store={store}>
            <Component {...pageProps} />
        </Provider>
    )
}

export default MyApp
