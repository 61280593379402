import { useMemo } from "react";
import { createStore } from "redux";
import {DEFAULT_CURRENT_PAGE, DEFAULT_SECTION} from './utils/constants'
import {decodeAccessToken, decodeToken} from './utils/helpers'

export interface notificationType {
  notificationId:number;
  title:string;
  description:string;
  image?:string;
  isRead: boolean;
  date:Date;
  promoButton: PromoButtonTypes;
};

export interface PromoButtonTypes {
  state: "enabled" | "disabled";
  promoButtonPressDttm: string | null;
  textPress: string | null;
  textPressed: string | null;
}

export interface notificationItemType extends notificationType {
  theme: 'dark' | 'light';
};

export interface stateTypes {
  idTokenInit: string;
  idToken: string;
  accessToken: string;
  refreshToken: string;
  refreshHandle: any;
  gamblerName: string;
  balance: string;
  freebets: Array<{
    id: string;
    value: string;
    exp?: string;
  }>;
  cardTag: string;
  delayForRefreshTokens: number;
  phone: string;
  qr: string;
  pin: string;
  refreshError: boolean;
  balanceTypeList: Array<{
    name: string;
    size: number;
    availaible: boolean;
    rules?: string[];
    id: string;
    restrictions?: string;
  }>;
  wsGCliOpen: boolean;
  digitainApiToken: string;
  iFrameReady: boolean;
  rulesOpen: boolean;
  isCybersoprt: boolean;
  sportUrl: string | undefined;
  freebetsFromServer: Array<{
    beginDatetime: string;
    betMaxFactor: number;
    betMinFactor: number;
    endDatetime: string;
    freeBetId: string;
    value: number;
  }>;
  currentBalanceId: string;
  wsToV3: WebSocket | null;
  subscribeInterval: number;
  authFormOpen: boolean;
  errorCode: number | null;
  isError: boolean;
  wsGcli: WebSocket | null;
  walletId: string | null;
  deviceType: string;
  section: string;
  currentPage: string;
  partnerId: string | null;
  hallId: string | null;
  wsToCardService: WebSocket | null;
  authStatus: "not_started" | "success" | "pending" | "error";
  qrCheckStatus: "error" | "pending" | "success";
  enableAutoLogin: boolean;
  sportLanguages: Array<string>;
  theme: "dark" | "light";
  userTheme: "dark" | "light";
  view: "LatinoView" | "ESport";
  lang: string;
  isNotificationOpen: boolean;
  unreadNotificationsCount: number | undefined;
  notifications: Array<notificationItemType>;
  betHistoryOpen: boolean;
}

interface balanceTypes {
  name: string;
  size: number;
  availaible: boolean;
  rules?: string[];
  id: string;
  restrictions?: string;
}

interface freebetTypes {
  beginDatetime: string;
  betMaxFactor: number;
  betMinFactor: number;
  endDatetime: string;
  freeBetId: string;
  value: number;
  betType: string | null;
}

interface actionTypes {
  payload: any;
  type: string;
}

let store: any;

const initialState: stateTypes = {
  idTokenInit: `${process.env.NEXT_PUBLIC_ID_TOKEN_INIT}`,
  idToken: "",
  accessToken: "",
  refreshToken: "",
  refreshHandle: null,
  digitainApiToken: "-",
  gamblerName: "",
  balance: "0",
  freebets: [],
  cardTag: "",
  delayForRefreshTokens: 1000000000,
  phone: "",
  qr: "",
  pin: "",
  refreshError: false,
  balanceTypeList: [],
  wsGCliOpen: false,
  wsGcli: null,
  sportUrl: "",
  iFrameReady: false,
  rulesOpen: false,
  isCybersoprt: false,
  section: DEFAULT_SECTION,
  currentPage: DEFAULT_CURRENT_PAGE,
  freebetsFromServer: [],
  currentBalanceId: "",
  wsToV3: null,
  subscribeInterval: 20,
  authFormOpen: false,
  errorCode: null,
  isError: false,
  walletId: null,
  partnerId: "",
  hallId: "",
  wsToCardService: null,
  authStatus: "not_started",
  qrCheckStatus: "pending",
  deviceType: "",
  enableAutoLogin: true,
  sportLanguages: ['ru'],
  theme: 'dark',
  userTheme: 'dark',
  view: 'ESport',
  lang: 'ru',
  isNotificationOpen: false,
  unreadNotificationsCount: undefined,
  notifications: [],
  betHistoryOpen: false
};

const reducer = (state = initialState, action: actionTypes): stateTypes => {
  if(process.env.NODE_ENV !== 'development') console.debug(action.type, action.payload);
  switch (action.type) {
    case "SET_TOKENS_FROM_GCLI": {
      return {
        ...state,
        idToken: action.payload.idToken,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        gamblerName: decodeAccessToken(action.payload.accessToken).data.accountId,
        delayForRefreshTokens: decodeToken(action.payload.idToken).exp * 1000 - Date.now() - 120000,
        sportLanguages: decodeToken(action.payload.idToken).sportLanguages,
      };
    }
    case "GET_TOKENS_FROM_V3": {
      return {
        ...state,
        idToken: action.payload.idToken,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        gamblerName: action.payload.gamblerName,
        delayForRefreshTokens: action.payload.delayForRefreshTokens,
        sportLanguages: action.payload.sportLanguages,
      };
    }
    case "REFRESH_TOKENS": {
      return {
        ...state,
        idToken: action.payload.idToken,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        gamblerName: action.payload.gamblerName,
        delayForRefreshTokens: action.payload.delayForRefreshTokens,
        sportLanguages: decodeToken(action.payload.idToken).sportLanguages,
      };
    }
    case "SET_REFRESH_HANDLE": {
      return {
        ...state,
        refreshHandle: action.payload.refreshHandle
      }
    }
    case "RESET_TOKENS": {
      return {
        ...state,
        idToken: "",
        accessToken: "",
        refreshToken: "",
        gamblerName: "",
        delayForRefreshTokens: 1000000000,
        digitainApiToken: "-"
      };
    }
    case "SET_PHONE_PIN": {
      return {
        ...state,
        phone: action.payload.phone,
        pin: action.payload.pin,
      };
    }
    case "SET_QR_PIN": {
      return {
        ...state,
        qr: action.payload.qr,
        pin: action.payload.pin,
      }
    }
    case "SET_QR_STATUS": {
      return {
        ...state,
        qrCheckStatus: action.payload,
      }
    }
    case "SET_CARD_TAG": {
      return {
        ...state,
        cardTag: action.payload,
      }
    }
    case "SET_PARTNER_ID": {
      return {
        ...state,
        partnerId: action.payload
      }
    }
    case "SET_HALL_ID": {
      return {
        ...state,
        hallId: action.payload
      }
    }
    case "SET_DEVICE_TYPE": {
      return {
        ...state,
        deviceType: action.payload
      }
    }
    case "SET_SPORT_URL": {
      return {
        ...state,
        sportUrl: action.payload
      }
    }
    case "PARSE_BALANCE": {
      const parsedBalance = {
        name: "Денежный баланс",
        size: Math.floor(action.payload),
        availaible: true,
        id: "money",
      };
      const indexParsedBalance = state.balanceTypeList.findIndex(
        (el) => el.id === parsedBalance.id
      );
      let newBalanceTypeList =
        indexParsedBalance > -1
          ? [
              parsedBalance,
              ...state.balanceTypeList.slice(
                indexParsedBalance + 1,
                state.balanceTypeList.length
              ),
            ]
          : [parsedBalance, ...state.balanceTypeList];

      return {
        ...state,
        balanceTypeList: newBalanceTypeList,
      };
    }
    case "PARSE_BONUS": {
      const parsedBonus = {
        name: "Бонусный баланс",
        size: Math.floor(action.payload),
        availaible: false,
        id: "bonus",
      };

      const indexParsedBonus = state.balanceTypeList.findIndex(
        (el) => el.id === "bonus"
      );
      const newBalanceTypeList =
        indexParsedBonus > -1
          ? [
              state.balanceTypeList[0],
              parsedBonus,
              ...state.balanceTypeList.slice(2),
            ]
          : [
              state.balanceTypeList[0],
              parsedBonus,
              ...state.balanceTypeList.slice(1),
            ];

      return {
        ...state,
        balanceTypeList: newBalanceTypeList,
      };
    }
    case "PARSE_FREEBETS": {
      const freebetsFromServer = action.payload;
      const parsedFreebets = action.payload.map(
        (freebet: freebetTypes): balanceTypes => {
          const endDateTime = new Date(Date.parse(freebet.endDatetime));
          const replacePoint = (value: string) => {
            if (value.includes(".")) {
              const pointIndex = value.indexOf(".");
              const newString = value.slice(0, pointIndex) + "," + value.slice(pointIndex + 1);
              return newString;
            } else {
              return value
            }
          }
          const freebetRules = [];
          if (freebet.betMinFactor) {
            freebetRules.push(`с мин. коэфф. ${replacePoint(freebet.betMinFactor.toString())}`);
          }
          if (freebet.betMaxFactor) {
            freebetRules.push(`с макс. коэфф. ${replacePoint(freebet.betMaxFactor.toString())}`);
          }
          if (freebet.betType) {
            if (freebet.betType === "ordinary") {
              freebetRules.push("ординар");
            } else if (freebet.betType === "express") {
              freebetRules.push("экспресс");
            }
          }

          const year = `${endDateTime.getFullYear()}`;
          const restrict = freebet.endDatetime
            ? `до ${endDateTime.getDate()}.${
                endDateTime.getMonth() + 1
              }.${year.slice(2)}`
            : "";

          return {
            name: "Фрибет",
            size: freebet.value,
            availaible: true,
            rules: freebetRules,
            id: freebet.freeBetId,
            restrictions: restrict,
          };
        }
      );

      const indexParsedBalance = state.balanceTypeList.findIndex(
        (el) => el.id === "money"
      );
      const indexParsedBonus = state.balanceTypeList.findIndex(
        (el) => el.id === "bonus"
      );
      let newBalanceTypeList = [...state.balanceTypeList];
      if (indexParsedBalance === -1 && indexParsedBalance === -1) {
        newBalanceTypeList = [...parsedFreebets];
      } else if (indexParsedBalance > -1 && indexParsedBonus === -1) {
        newBalanceTypeList = [
          ...state.balanceTypeList.slice(0, indexParsedBalance + 1),
          ...parsedFreebets,
        ];
      } else if (indexParsedBalance > -1 && indexParsedBonus > -1) {
        newBalanceTypeList = [
          state.balanceTypeList[indexParsedBalance],
          state.balanceTypeList[indexParsedBonus],
          ...parsedFreebets
        ]
      }
      return {
        ...state,
        balanceTypeList: newBalanceTypeList,
        freebetsFromServer: freebetsFromServer,
      };
    }
    case "DIGITAIN_TOKEN_GET": {
      return {
        ...state,
        digitainApiToken: action.payload,
      };
    }
    case "BETHISTORY_OPEN": {
      return {
        ...state,
        betHistoryOpen: !state.betHistoryOpen
      }
    }
    case "SET_AUTH_STATUS": {
      return {
        ...state,
        authStatus: action.payload,
      }
    }
    case "SET_WALLET_ID": {
      return {
        ...state,
        walletId: action.payload,
      };
    }
    case "TOGGLE_WS_GCLI_OPEN": {
      return {
        ...state,
        wsGCliOpen: action.payload,
      };
    }
    case "SET_GCLI_TO_STATE": {
      return {
        ...state,
        wsGcli: action.payload,
      };
    }
    case "IFRAME_ACTIVE": {
      return {
        ...state,
        iFrameReady: true,
      };
    }
    case "IFRAME_DISABLED": {
      return {
        ...state,
        iFrameReady: false,
      };
    }
    case "CYBERSPORT_ACTIVE": {
      return {
        ...state,
        isCybersoprt: true,
      };
    }
    case "CYBERSPORT_DISABLED": {
      return {
        ...state,
        isCybersoprt: false,
      };
    }
    case "TOGGLE_RULES_MODAL": {
      return {
        ...state,
        rulesOpen: !state.rulesOpen,
      };
    }
    case "SET_CURRENT_BALANCE_ID": {
      return {
        ...state,
        currentBalanceId: action.payload,
      };
    }
    case "SET_WS_TO_V3": {
      return {
        ...state,
        wsToV3: action.payload,
      };
    }
    case "SET_WS_TO_CARD_SERVICE": {
      return {
        ...state,
        wsToCardService: action.payload,
      }
    }
    case "TOOGLE_AUTHFORM": {
      return {
        ...state,
        authFormOpen: !state.authFormOpen,
      };
    }
    case "SHOW_ERROR": {
      return {
        ...state,
        isError: true,
        errorCode: action.payload,
      };
    }
    case "HIDE_ERROR": {
      return {
        ...state,
        isError: false,
      };
    }
    case "REFRESH_ERROR_TEXT": {
      return {
        ...state,
        errorCode: null,
      };
    }
    case "LOGOUT": {
      return {
        ...initialState,
        enableAutoLogin: false,
        wsGcli: state.wsGcli,
        wsGCliOpen: state.wsGCliOpen,
        wsToCardService: state.wsToCardService,
        sportUrl: process.env.NEXT_PUBLIC_SPORT_URL_DEFAULT,
        isError: state.isError,
        errorCode: state.errorCode,
        refreshHandle: state.refreshHandle
      };
    }
    case "NAVIGATE_CLICK": {
      return {
        ...state,
        section: action.payload.section,
        currentPage: action.payload.currentPage,
      };
    }
    case "SAVE_USER_THEME": {
      return {
        ...state,
        userTheme: action.payload,
        theme: state.view === "LatinoView" ? "dark" : action.payload,
      };
    }
    case "SWITCH_THEME": {
      return {
        ...state,
        theme: action.payload,
      };
    }
    case "SWITCH_VIEW": {
      return {
        ...state,
        view: action.payload,
      };
    }
    case "SWITCH_LANG": {
      return {
        ...state,
        lang: action.payload,
      };
    }
    case "TOGGLE_NOTIFICATIONS": {
      return {
        ...state,
        isNotificationOpen: !state.isNotificationOpen
      };
    }
    case "SET_NOTIFICATIONS": {
      return {
        ...state,
        unreadNotificationsCount: action.payload.filter((n:any) => !n.isRead).length,
        notifications: action.payload
      };
    }
    default:
      return state;
  }
};

function initStore(preloadedState = initialState): stateTypes {
  return createStore(reducer, preloadedState);
}

export function useStore(initialState: stateTypes) {
  store = useMemo(() => initStore(initialState), [initialState]);
  return store;
}
