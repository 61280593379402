import jwt from "jsonwebtoken";

interface Data {
  data: { name: string; accountId: string; exp: number, sportLanguages: Array<string> },
  exp?: number,
  iat?: number
}

export const decodeToken = (idToken: string): { name: string;  accountId: string; exp: number, sportLanguages: Array<string> } => {
    try {
      const token: any = jwt.decode(idToken);
      if(!token) {
        return {name: "", accountId: "", exp: 0, sportLanguages: ['ru']};
      }
      return token;
    } catch (error) {
      console.warn('Decode token error', error)
      return {name: "", accountId: "", exp: 0, sportLanguages: ['ru']};
    }
  };

export const decodeAccessToken = (idToken: string): Data => {
    try {
      const token: any = jwt.decode(idToken);
      if (!token) {
        return {
          data: { name: "", accountId: "", exp: 0, sportLanguages: ['ru'] }
        };
      }
      return token;
    } catch (error) {
      console.warn('Decode token error', error)
      return {
        data: { name: "", accountId: "", exp: 0, sportLanguages: ['ru'] }
      };
    }
  };